import './carousel.scss'
import { wait, every, cancelInterval } from 'components/async'
import CustomHTMLElement from 'html-element'

const CURRENT_SLIDE_CLASS = 'current'

const TRANSITION = '250ms ease-out'
const TRANSITION_DURATION = 0.250
const AUTOPLAY_INTERVAL = 5

export default class CarouselGallery extends CustomHTMLElement {
  _runSetup() {
    super._runSetup()

    this.previousButton = this.querySelector('.carousel__button--prev')
    this.nextButton = this.querySelector('.carousel__button--next')

    this.track = this.querySelector('.carousel__track')
    let slides = Array.from(this.querySelectorAll('.carousel__slide'))
    this.trueSlideCount = slides.length
    this.slideWidth = slides[0].clientWidth

    if (this.trueSlideCount >= 2) {
      slides.forEach((slide, index) => {
        if (index <= 3) {
          this.track.append(slide.cloneNode(true))
        }
      })

      for (let i = this.trueSlideCount - 1; i >= 0; i--) {
        if (i >= (this.trueSlideCount - 1)) {
          this.track.prepend(slides[i].cloneNode(true))
        }
      }
    }
    this.index = 0

    slides[1].classList.add(CURRENT_SLIDE_CLASS)

    if (this.trueSlideCount > 1) {
      this.autoplay = this.hasAttribute('data-autoplay')
    } else {
      this.autoplay = false
    }

    this.setupEvents()
    this.startSlides(this.index)
    this.enableDragging()
  }

  disconnectedCallback() {
    if (typeof this.disableAutoplay === 'function') {
      this.disableAutoplay()
    }
  }

  //////////  Setup Events

  setupEvents() {
    this.previousButton.addEventListener('click', () => this.slideBackward(this.index))
    this.nextButton.addEventListener('click', () => this.slideForward(this.index))
    this.track.addEventListener('transitionend', () => this.disableTransitions(this.index))
    this.track.addEventListener('mouseenter', () => this.disableAutoplay())
    this.track.addEventListener('mouseleave', () => this.enableAutoplay())
  }

  //////////  Slides

  get slides() {
    return this.querySelectorAll('.carousel__slide')
  }

  get currentSlide() {
    return this.querySelector('.current')
  }

  slideBackward(index) {
    if (index <= 0) { return }
    this.showSlide(index, false)
  }

  slideForward(index) {
    if (index >= this.slides.length - 4) { return }
    this.showSlide(index, true)
  }

  startSlides(index) {
    this.showSlide(index, true)
  }

  showSlide(index, forwards) {
    if (this.isAnimating || this.isDragging) {
      return
    }
    this.isAnimating = true
    this.disableAutoplay()

    if (forwards) {
      this.index = index + 1

      this.track.style.transition = TRANSITION
      this.enableTransitions(this.index)

      wait().then(() => {
        this.currentSlide.classList.remove(CURRENT_SLIDE_CLASS)

        wait(TRANSITION_DURATION).then(() => {
          this.slides[this.index].classList.add(CURRENT_SLIDE_CLASS)

          this.isAnimating = false
          this.enableAutoplay()
        })
      })
    } else {
      this.index = index - 1
      this.track.style.transition = TRANSITION
      this.enableTransitions(this.index)

      wait().then(() => {
        this.currentSlide.classList.remove(CURRENT_SLIDE_CLASS)

        wait(TRANSITION_DURATION).then(() => {
          this.slides[this.index].classList.add(CURRENT_SLIDE_CLASS)

          this.isAnimating = false
          this.enableAutoplay()
        })
      })
    }
  }

  //////////  Transitions

  enableTransitions(index) {
    this.track.style.transform = `translateX(-${this.slideWidth * index}px)`
  }

  disableTransitions(index) {
    if (index >= this.slides.length - 4) {
      this.track.style.transition = 'none'
      this.index = 1
      this.enableTransitions(this.index)
    }

    if (index <= 0) {
      this.track.style.transition = 'none'
      this.index = this.trueSlideCount
      this.enableTransitions(this.index)
    }
  }

  //////////  Autoplay

  enableAutoplay() {
    if (this.autoplayInterval) {
      return
    }

    let interval = this.interval || AUTOPLAY_INTERVAL

    this.autoplayInterval = every(interval, () => {
      if (!this.matches(':hover') || !this.isDragging) {
        this.slideForward(this.index)
      }
    })
  }

  disableAutoplay() {
    if (!this.autoplayInterval) {
      return
    }
    cancelInterval(this.autoplayInterval)
    this.autoplayInterval = null
  }

  ////////// Dragging

  enableDragging() {
    this.track.addEventListener('touchstart', (event) => this.setupDrag(event))
    this.track.addEventListener('touchmove', (event) => {
      if (this.dragHandlingTouch === true) {
        return this.handleDrag(event)
      } else if (this.dragHandlingTouch === false) {
        return true
      } else {
        return this.checkDrag(event)
      }
    })
    this.track.addEventListener('touchend', (event) => {
      if (this.dragHandlingTouch === true) {
        return this.finishDrag(event)
      }

      return true
    })
  }

  setupDrag(event) {
    let touch = event.touches[0]

    this.dragInitialTouch = {
      clientX: touch.clientX,
      clientY: touch.clientY
    }
    this.dragHandlingTouch = null
  }

  beginDrag() {
    this.isDragging = true
    this.classList.add('carousel--touch-event')

    return true
  }

  handleDrag(event) {
    let touch = event.touches[0]
    let movement = touch.clientX - this.dragInitialTouch.clientX
    this.track.style.transform = `translateX(-${(this.slideWidth * this.index) - movement}px)`

    return true
  }

  checkDrag(event) {
    let touch = event.touches[0]

    let movement = {
      x: Math.abs(touch.clientX - this.dragInitialTouch.clientX),
      y: Math.abs(touch.clientY - this.dragInitialTouch.clientY)
    }

    if (movement.x > movement.y && movement.x > 5) {
      this.dragHandlingTouch = true
      return this.beginDrag(event)
    } else if (movement.y > movement.x && movement.y > 5) {
      this.dragHandlingTouch = false
      return true
    } else {
      return true
    }
  }

  finishDrag(event) {
    let touch = event.changedTouches[0]
    let movement = touch.clientX - this.dragInitialTouch.clientX
    let catchDistance = this.clientWidth * 0.3

    this.clearDrag()

    if (movement > catchDistance) {
      this.slideBackward(this.index)
    } else if (movement < (catchDistance * -1)) {
      this.slideForward(this.index)
    }

    return true
  }

  clearDrag() {
    this.isDragging = false
    this.classList.remove('carousel--touch-event')

    wait().then(() => {
      this.track.style.tranform = 'none'
    })

    this.dragHandlingTouch = null
    this.dragInitialPageIndex = null
    this.isDragging = false
  }
}

window.customElements.define('carousel-gallery', CarouselGallery)
