import 'formatted-time'
import 'js-alerts'
import 'dropdown'
import 'field-container'
import 'onspace/assets/elements/calendar'
import 'onspace/assets/elements/fields/rich_text.scss'
import 'onspace/assets/elements/form'
import 'onspace/assets/elements/pagination'
import 'onspace/assets/elements/popover'
import 'onspace/assets/elements/toggle'
import 'scss-utils/button'

import 'base/color.scss'
import 'base/layout.scss'
import 'base/typography.scss'
import 'base/reset.scss'

import analytics from 'components/analytics'
import 'components/assets'
import 'components/links'
import 'components/scroll'
import 'components/ujs'

import 'elements/advertising/placement'
import 'elements/accordion'
import 'elements/badge'
import 'elements/button'
import 'elements/carousel'
import 'elements/collection'
import 'elements/content'
import 'elements/footer'
import 'elements/grid'
import 'elements/layout.scss'
import 'elements/list'
import 'elements/nav_bar'
import 'elements/page'
import 'elements/popover'
import 'elements/rich_text'
import 'elements/share'
import 'elements/tabs'
import 'elements/search/autocomplete'
import 'elements/search/form'
import 'elements/search/list'
import 'elements/svg'
import 'elements/video'
import 'elements/video/card'
import 'elements/video/iframe'
import 'elements/video/educational_video_card'
import 'elements/video/list'
import 'elements/banner'
import 'elements/table'


import '../elements/nav_bar_ext'
import '../elements/nav_sidebar'
import '../elements/home/card'
import '../elements/home/content'
import '../elements/news/article'
import '../elements/news/card'
import '../elements/news/list'
import '../elements/section/content'
import '../elements/feedback'
import '../elements/directors/main'
import '../elements/directors/profile'

import '../elements/race/runners'

window.analytics = analytics

analytics.injectGoogleAnalytics(window.config.googleAnalyticsId)
analytics.injectGoogleTagManager(window.config.googleTagId)
analytics.setupTurbolinksTracking()
